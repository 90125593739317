























































































































































import { Component, Vue } from 'vue-property-decorator';
import ViewWrapper from '../../components/ViewWrapper.vue';
import FlaskIcon from '../../icons/flask.vue';

@Component({
  components: {
    FlaskIcon,
    ViewWrapper,
  },
  setup() {
    return { };
  },
})
export default class Home extends Vue {}
